<template>
   
    <div class="content">
      <div class="screen">
        <van-button plain hairline size="small" @click="$refs.employee.$show(screenList, 'max')" icon="https://img01.yzcdn.cn/vant/user-active.png" type="info">
          {{ screenName ? screenName : '筛选销售' }}
        </van-button>
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <van-cell-group inset :border="false" v-for="(item, index) in list" :key="index" class="list-item">
          <van-cell :border="false" style="align-items: center;">
            <template #title>
              <span class="custom-title list-item-title">
                <van-image
                  width="44"
                  height="44"
                  fit="cover"
                  round
                  :src="item.avatar"
                />
                <div>
                  {{ item.name }}
                  <div class="time"><van-icon name="friends-o" /> 跟进销售：{{ item.employee_name }}</div>
                  <div class="time">最近跟进时间：{{ item.last_follow_time }}</div>
                </div>
              </span>
            </template>
            <!-- <template>
              <div class="time">最近跟进时间：{{ item.last_follow_time }}</div>
            </template> -->
          </van-cell>
        </van-cell-group>
        <div v-if="finished && list.length" slot="finished">无更多数据</div>
      </van-list>
      <van-empty
        v-if="finished && !list.length"
        class="custom-image"
        :image="require('@/assets/images/search-no-data.svg')"
        description="暂无数据"
      />
      <employeeIndex ref="employee" @change="userChange"/>
  </div>
</template>
<script>
import { pushUser } from '@/plugins/utils'
import { ContactFollowApi } from '@/api/contactFollow'
import employeeIndex from "@/views/channelCode/components/employeeIndex";
export default {
  components: {
    employeeIndex
  },
  data() {
    return {
      pushUser,
      serchValue: '',
      loading: false,
      finished: false,
      list: [
      ],
      page: 1,
      perPage: 15,

      screenList: '',
      screenName: '',
    }
  },
  created () {
    window.scrollTo(0, 0)
  },
 
  methods: {
    userChange(data, info) {
      console.log(data)
      console.log(info)
      if (data.length) {
        this.screenList = data
        this.screenName = data[0].name
        
      } else {
        this.screenList = []
        this.screenName = ''
      }
      this.page = 1
      this.list = []
      this.finished = false
      this.onLoad()
    },
    checkDetail (item) {
      pushUser(item)
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back()
    },
    onLoad () {
      this.loading = true
      let params = {
        page: this.page,
        page_size: this.perPage
      }
      if (this.screenList[0]) {
        params.employee_id = this.screenList[0].id
      }
      ContactFollowApi.waitList(params).then(res => {
        this.loading = false
        if(res.data.lists.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.list = this.list.concat(res.data.lists.data || [])
      })
    },
    // 路由跳转
    routerPush(e) {
      this.$router.push(e)
    },
  }
}
</script>
<style scoped lang="less">
.content {
    .screen {
      margin-bottom: 16px;
      text-align: right;
    }

    /deep/.van-cell-group {
      margin: 0;
    }
    // background: #f4f5f7;
    padding: 6px 10px 16px;

    .list-item {
      margin-bottom: 8px;
      // background: #fff;
      // box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);

      /deep/.van-cell {
        background-color: #FBFBFB;

        .van-cell__value {
          flex: unset;
        }
      }

      &-title {
        display: flex;
        align-items: center;

        .van-image {
          margin-right: 8px;
        }
      }

      .time {
        font-size: 12px;
        color: #888;
        line-height: 18px;
      }

      &-label {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
      }

      &-value {
        
      }
    }      
  }
</style>
