<template>
  <div class="content">
    <div class="header__right">
      <div class="switch">
        <a-select style="min-width: 80px" size="small" :value="checkType" @change="changeCheckType">
          <a-select-option :key="1">今天</a-select-option>
          <a-select-option :key="2">昨天</a-select-option>
          <a-select-option :key="3">前天</a-select-option>
        </a-select>
      </div>
    </div>
    
    <van-list
      v-model="loading"
      :finished="finished"
      @load="onLoad"
    >
      <van-cell-group inset :border="false" v-for="(item, index) in list" :key="index" class="list-item" @click="$router.push('/waitFollowTip/contact?employee_id='+item.employee_id)">
        <van-cell :border="false" style="align-items: center;"  is-link>
          <template #title>
            <span class="custom-title list-item-title">
              <van-image
                width="44"
                height="44"
                fit="cover"
                round
                :src="item.employee.avatar"
              />
              <div>
                {{ item.employee.name }}
              </div>
            </span>
          </template>
          <template>
            <van-icon name="friends-o" />未跟进客户数 {{item.waitFollowNum}}
          </template>
        </van-cell>
      </van-cell-group>
      <div v-if="finished && list.length" slot="finished">无更多数据</div>
    </van-list>
    <van-empty
      v-if="finished && !list.length"
      class="custom-image"
      :image="require('@/assets/images/search-no-data.svg')"
      description="暂无数据"
    />
  </div>
</template>
<script>
import { pushUser } from '@/plugins/utils'
import { waitFollowTip } from '@/api/waitFollowTip'
import Vue from 'vue';
import { Popover } from 'vant';
Vue.use(Popover);
export default {
  data() {
    return {
      pushUser,
      checkType: 1,
      serchValue: '',
      loading: false,
      finished: false,
      list: [],
      page: 1,
      perPage: 15,
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.startDate = this.dateFormat(new Date())
    this.endDate = this.dateFormat(new Date())
  },
 
  methods: {
    checkDetail (item) {
      pushUser(item)
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back()
    },
    changeCheckType (v) {
      this.checkType = v
      switch (v) {
        case 1:
          this.startDate = this.dateFormat(new Date())
          this.endDate = this.dateFormat(new Date())
          break
        case 2:
          this.startDate = this.dateFormat(new Date() - (86400 * 1 * 1000))
          this.endDate = this.dateFormat(new Date() - (86400 * 1 * 1000))
          break
        case 3:
          this.startDate = this.dateFormat(new Date() - (86400 * 2 * 1000))
          this.endDate = this.dateFormat(new Date() - (86400 * 2 * 1000))
          break
      }
      this.page =  1
      this.list = []
      this.onLoad()
    },
    onLoad () {
      this.loading = true
      waitFollowTip.employee({
        startDate: this.startDate,
        endDate: this.endDate,
        page: this.page,
        page_size: this.perPage
      }).then(res => {
        this.loading = false
        if(res.data.lists.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.list = this.list.concat(res.data.lists.data || [])
      })
    },
    // 路由跳转
    routerPush(e) {
      this.$router.push(e)
    },
    // 日期格式化
    dateFormat(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
      return Y + M + D
    },
  }
}
</script>
<style scoped lang="less">
.index {
  font-size: 14px;
  height: 100%;
  background: #f4f5f7;

  /deep/ .van-nav-bar__content {
    background: #2b63cd;
    color: #fff;
  }

  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }

  /deep/ .van-nav-bar__text {
    color: #fff;
  }

  /deep/ .van-nav-bar__title {
    color: #fff;
  }

  .content {

    .header__right {
      text-align: right;
      margin-bottom: 10px;

      .svg-icon {
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden
      }

      .switch {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .switch__item--active {
          font-weight: 500;
          color: #000;
        }

        .switch__item {

          padding: 0 3px;
          margin-left: 7px;
          color: #8c8c8c;
          font-size: 12px;
        }
      }
    }

    /deep/.van-cell-group {
      margin: 0;
    }
    // background: #f4f5f7;
    padding: 4px 10px 10px;

    .list-item {
      margin-bottom: 8px;
      // background: #fff;
      // box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);

      /deep/.van-cell {
        background-color: #FBFBFB;
        padding: 8px;

        .van-cell__value {
          flex: unset;
        }
      }

      &-title {
        display: flex;
        align-items: center;

        .van-image {
          margin-right: 8px;
        }
      }

      &-label {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
      }

      &-value {
        
      }

      /deep/.van-cell__right-icon {
        // vertical-align: bottom;
        // font-size: 12px;
        height: auto;
        color: #ddd;
      }
    }      
  }
}
</style>
