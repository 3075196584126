<template>
  <div class="index">
    <div class="content" style="overflow:hidden;">
      <van-tabs v-model="active" @change="tabChange" color="#1890ff" title-active-color="#1890ff">
        <van-tab name="1" title="客户跟进">
          <employee></employee>
        </van-tab>
        <van-tab name="2" title="无记录客户">
          <waitList></waitList>
        </van-tab>
        
      </van-tabs>
    </div>
  </div>
</template>

<script>
import waitList from './components/waitList'
import contact from './components/contact'
import employee from './components/employee'
import { mapGetters } from 'vuex'
export default {
  components: {
    waitList,
    contact,
    employee
  },
  data() {
    return {
      active: '1',
    }
  },
  watch: {
    // active(e){
    //   const that = this
    //   if(e == '2'){
    //     setTimeout(function () {
    //       that.categoryCategory()
    //     },1)
    //   }
    // }
  },
  computed:{
		...mapGetters([
			'userPermission'
		])
	},
  created () {
    console.log(this.userPermission)
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    tabChange (name, title) {
    },
  },
  mounted() {
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__line {
  bottom: 9px;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 8px;
}

/deep/ .van-tabs__wrap {
  height: 54px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
  padding-top: 10px;
}

/deep/ .van-tabs__nav {
  background: #f4f5f7;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

/deep/.van-action-sheet {
  max-height: 98%;
}

.index {
  font-size: 14px;
  height: 100%;
  background: #f4f5f7;

  p {
    margin: 0;
  }

  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }

  .content {
    min-height: 92%;
    // padding-top: 5px;

    .basecard {
      background-color: #fff;
      padding: 0 0 24px 0;

      .header {
        height: 65px;
        padding: 0 15px 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header__title {
          line-height: 21px;
          font-weight: 500;
          color: #000;
        }
      }
     
    }
  }
}

</style>
